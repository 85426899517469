import React, {
  Suspense, useEffect, useState, useRef,
} from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ModalProvider } from 'styled-react-modal';
import request from '../utils/request';
import { ModalBackground } from '../components/modal';
import GlobalStyles from '../GlobalStyles';
import getConvertedToCssVariables from '../utils/getConvertedToCssVariables';

const LoginLegacy = React.lazy(() => import('./LoginLegacy'));
const Login = React.lazy(() => import('./Login'));
const AccountLegacy = React.lazy(() => import('./AccountLegacy'));
const Account = React.lazy(() => import('./Account'));
const UserPreferenceLegacy = React.lazy(() => import('./UserPreferenceLegacy'));
const UserPreference = React.lazy(() => import('./UserPreference'));
const CancelSubscriptions = React.lazy(() => import('./CancelSubscriptions'));

const TEST = {
  LOGIN_REGISTRATION: Login,
  LOGIN_REGISTRATION_LEGACY: LoginLegacy,
  ACCOUNT: Account,
  ACCOUNT_LEGACY: AccountLegacy,
  PREFERENCE_CENTRE: UserPreference,
  PREFERENCE_CENTRE_LEGACY: UserPreferenceLegacy,
  CANCEL_SUBSCRIPTION: CancelSubscriptions,
};

const Pages = () => {
  const location = useLocation();
  const [pageType, setPageType] = useState();
  const pageConfig = useRef();
  const globalStyles = useRef();
  const [siteStylesVar, setSiteStylesVar] = useState();

  const getPageConfig = async (path) => {
    try {
      const { response } = await request({
        path: `/zephr/media/pages/details?url=${path}`,
      });
      pageConfig.current = JSON.parse(response);
      const {
        type, zephrPagesUpdatedStylingEnabled, zephrPagesUpdatedStylingExtendedEnabled, siteStyles,
      } = pageConfig.current;
      setSiteStylesVar(siteStyles);
      if (type === 'PREFERENCE_CENTRE' && !zephrPagesUpdatedStylingEnabled) {
        setPageType('PREFERENCE_CENTRE_LEGACY');
      } else if (type === 'LOGIN_REGISTRATION' && !zephrPagesUpdatedStylingExtendedEnabled) {
        setPageType('LOGIN_REGISTRATION_LEGACY');
      } else if (type === 'ACCOUNT' && !zephrPagesUpdatedStylingExtendedEnabled) {
        setPageType('ACCOUNT_LEGACY');
      } else {
        globalStyles.current = getConvertedToCssVariables(siteStyles);
        setPageType(type);
      }
    } catch (e) {
      console.error('Failed to fetch page configs:', e);
    }
  };

  useEffect(() => {
    getPageConfig(location.pathname);
  }, [location.pathname]);

  const createLinkTag = (linkData) => {
    const link = document.createElement('link');
    linkData.forEach((e) => {
      link[e.key] = e.value;
    });
    return link;
  };

  useEffect(() => {
    if (siteStylesVar) {
      const parsedSiteStyles = JSON.parse(siteStylesVar);
      const bodyFont = parsedSiteStyles.typography.body.font.replace(/ /g, '+');
      const titleFont = parsedSiteStyles.typography.title.font.replace(/ /g, '+');
      const preloadFontLinks = [];
      preloadFontLinks[0] = createLinkTag([
        { key: 'rel', value: 'preconnect' },
        { key: 'href', value: 'https://fonts.gstatic.com' },
      ]);
      preloadFontLinks[1] = createLinkTag([
        { key: 'rel', value: 'preload' },
        { key: 'as', value: 'style' },
        { key: 'href', value: `https://fonts.googleapis.com/css2?family=${bodyFont}&family=${titleFont}&display=swap` },
      ]);
      preloadFontLinks[2] = createLinkTag([
        { key: 'rel', value: 'stylesheet' },
        { key: 'href', value: `https://fonts.googleapis.com/css2?family=${bodyFont}&family=${titleFont}&display=swap` },
      ]);
      preloadFontLinks.forEach((e) => {
        document.getElementById('root').children[0].appendChild(e);
      });
      return () => {
        preloadFontLinks.forEach((e) => {
          document.getElementById('root').children[0].removeChild(e);
        });
      };
    }
    return null;
  }, [siteStylesVar]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <GlobalStyles styles={globalStyles.current} />
      <ModalProvider backgroundComponent={ModalBackground}>
        <Switch>
          {pageType && (
            <Route
              render={() => React.createElement(TEST[pageType], { pageConfig: pageConfig.current })}
            />
          )}
        </Switch>
      </ModalProvider>
    </Suspense>
  );
};

export default Pages;
