import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const PrimaryMessage = styled.div`
  font-size: 20px;
  display: flex;
  align-items: center;
  ${({ styleGuide }) => styleGuide && `
    font-family: var(--zephr-typography-body-font);
    color: var(--zephr-color-text-main);
  `}
`;

const SecondaryMessage = styled.div`
  font-size: 17px;
  color: #4f4f4f;
  margin: 6px 0 0 32px;
  ${({ styleGuide }) => styleGuide && `
    font-family: var(--zephr-typography-body-font);
    color: var(--zephr-color-text-main);
  `}
`;

const Icon = styled.div`
  display: flex;
  font-size: 22px;
  margin-right: 8px;

  color: ${({ type, styleGuide }) => {
    switch (type) {
      case 'success': return styleGuide ? 'var(--zephr-color-action-main)' : '#00bc08';
      case 'warning': return '#fdc543';
      case 'error': return styleGuide ? 'var(--zephr-color-warning-main)' : '#d90b00';
      default: return '#00bc08';
    }
  }};
`;

const StyledAlert = styled.div`
  width: 100%;
  padding: 15px 0 50px;

  ${({ noMargin }) => noMargin && `
    padding: 0;
  `}
`;

export const useAlert = () => {
  const [isShowingAlert, setIsShowingAlert] = useState(false);
  const [msg, setMsg] = useState({ primary: '', secondary: '' });

  const setAlertMsg = useCallback((message) => {
    if (message?.primary) {
      setIsShowingAlert(true);
      setMsg(message);
    } else {
      setIsShowingAlert(false);
    }
  }, []);

  return {
    isShowingAlert,
    alertMsg: msg,
    setAlertMsg,
  };
};

export const Alert = styled(({
  type,
  isShowingAlert,
  primaryMessage,
  secondaryMessage,
  styleGuide,
  noMargin,
}) => (isShowingAlert ? (
  <StyledAlert noMargin={noMargin}>
    <PrimaryMessage styleGuide>
      <Icon type={type} styleGuide={styleGuide}>
        <FontAwesomeIcon
          icon={type === 'success' ? faCheckCircle : faExclamationCircle}
        />
      </Icon>
      { /* eslint-disable-next-line react/no-danger */ }
      <div dangerouslySetInnerHTML={{ __html: primaryMessage }} />
    </PrimaryMessage>
    <SecondaryMessage styleGuide>
      {secondaryMessage}
    </SecondaryMessage>
  </StyledAlert>
) : null))``;

export const WarningAlert = styled(Alert).attrs({
  type: 'warning',
})``;

export const ErrorAlert = styled(Alert).attrs({
  type: 'error',
})``;

export const SuccessAlert = styled(Alert).attrs({
  type: 'success',
})``;

export const AlertMessage = styled.p`
  padding: 0;
  padding-left: 30px;
  margin: 0;
  font-weight: normal;
`;

Alert.propTypes = {
  type: PropTypes.oneOf(['warning', 'error', 'success']),
  isShowingAlert: PropTypes.bool,
  primaryMessage: PropTypes.string.isRequired,
  secondaryMessage: PropTypes.string,
};
