const getConvertedToCssVariables = (siteStyles) => {
  if (!siteStyles) return '';
  let acc = '';
  const parsedSiteStyles = JSON.parse(siteStyles);

  const buildString = (styleGuide, variablePrefix = '') => {
    if (styleGuide !== null && typeof styleGuide === 'object') {
      Object.entries(styleGuide).forEach(([key, value]) => {
        buildString(
          value,
          variablePrefix.concat(`${variablePrefix ? '-' : ''}${key}`),
        );
      });
    } else if (variablePrefix === 'typography-body-font') {
      acc = acc.concat(`--zephr-${variablePrefix}:${styleGuide}, ${parsedSiteStyles.typography.body.fallbackFont};\n`);
    } else if (variablePrefix === 'typography-title-font') {
      acc = acc.concat(`--zephr-${variablePrefix}:${styleGuide}, ${parsedSiteStyles.typography.title.fallbackFont};\n`);
    } else {
      acc = acc.concat(`--zephr-${variablePrefix}:${styleGuide};\n`);
    }
  };

  buildString(parsedSiteStyles);
  return acc;
};

export default getConvertedToCssVariables;
