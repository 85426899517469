import React from 'react';
import {
  BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';
import OAuthLogin from './OAuthLogin';
import OAuthError from './OAuthError';
import Pages from './pages';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/zephr/oauth2" render={() => <OAuthLogin />} />
        <Route exact path="/zephr/oauth2/error" render={() => <OAuthError />} />
        <Route render={() => <Pages />} />
      </Switch>
    </Router>
  );
}

export default App;
