import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  SubmitButton, Modal, ModalText, ModalTitle,
} from './StyledComponentsLegacy';
import { Spacer } from './StyledComponentsCommon';

const ErrorWrapper = styled.section`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 50px;
`;

const OAuthError = () => {
  const history = useHistory();
  return (
    <ErrorWrapper>
      <Modal>
        <ModalTitle>Something went wrong</ModalTitle>
        <ModalText>
          We&apos;re sorry we were unable to log you in at this time. Please use the back button to go back to where you were.
        </ModalText>
        <Spacer />
        <SubmitButton noMargin onClick={() => history.goBack()}>Back</SubmitButton>
      </Modal>
    </ErrorWrapper>
  );
};

export default OAuthError;
