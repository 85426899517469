import React, {
  useState, useEffect, useRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { Card, device } from './StyledComponentsCommon';

export const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  line-height: 30px;
  font-size: 19px;
  color: #000;
  
  ${({ withMargin }) => withMargin && `
    margin-bottom: 20px;
  `}

  ${({ noMargin }) => noMargin && `
    margin-bottom: 0;
  `}
`;
export const ErrorMsg = styled.span`
  color: #d90b00;
  font-size: 16px;
  ${({ pushUp }) => pushUp && `
    display: block;
    margin-top: -15px;
  `}
  ${({ styleGuide }) => styleGuide && `
    color: var(--zephr-color-warning-main);
  `}
`;
export const Input = styled(({ error, ...props }) => (
  <>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <input {...props} />
    {error?.show && <ErrorMsg>{error?.msg}</ErrorMsg>}
  </>
))`
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  padding: 0px 8px;
  font-size: 17px;
  border: 1px solid #ccc;
  background-color: #fafafa;
  border-radius: 9px;
  transition : border-color .25s ease, box-shadow .25s ease;
  outline: 0;
  color: #000;
  background-color: #fafafa;
  box-shadow: inset 0px 1px 2px rgba(0,0,0,0.11);

  ${({ error }) => error?.show && `
    border-color: #d90b00;
    box-shadow: inset 0px 1px 4px rgba(217,11,0,0.5);
  `} 
`;
export const Button = styled.button.attrs({
  type: 'button',
})`
  height: 50px;
  padding: 0 20px;
  text-decoration: none;
  text-transform:capitalize;
  text-align: center;
  border-radius: 9px;
  font-size: 18px;
  font-weight: normal;
  cursor: pointer;
  border: 0;
  color: #4f4f4f;
  background-color: #f1f1f1;
  transition: backdrop-filter .2s, background-color .2s;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  &:focus,
  &:hover {
    background-color: #f1f1f1;
    outline: 0;
  }
  ${({ noMargin }) => noMargin && `
    margin-bottom: 0;
  `}  
`;
export const PrimaryButton = styled(Button)`
  background-color: #006edb;
  color: #fff;
  position: relative;
  overflow: hidden;

  &:focus,
  &:hover {
    background-color: #005ebd;
  }

  ${({ disabled }) => disabled && `
    background-color: #f1f1f1;
    color: #ababab;
    &:hover {
      background-color: #e1e1e1;
    }
  `}
`;
const StyledSubmitButton = styled(PrimaryButton).attrs({
  type: 'submit',
})``;
export const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

const fadeOutAnimation = () => css`
  animation: ${fadeOut} .3s .5s;
  animation-fill-mode: forwards;
`;

const ProgressBar = styled.div`
  background-color: #0054a8;
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  z-index: 0;
  ${({ active }) => active && `
    width: 100%;
    transition: .5s width cubic-bezier(1, 0, 0.4, 1);
  `}
  ${({ active }) => (active && fadeOutAnimation)};
`;
const SubmitLabel = styled.div`
  position: relative;
  z-index: 1;
`;
export const SubmitButton = React.forwardRef(
  ({ children, onClick = () => null, ...props }, ref) => {
    const [active, setActive] = useState(false);
    const btnRef = useRef();
    const timer = useRef();
    const showProgress = () => {
      if (active) return;
      btnRef.current.blur();
      setActive(true);
      timer.current = setTimeout(() => {
        setActive(false);
        onClick();
      }, 3000);
    };

    useEffect(() => () => clearTimeout(timer.current), []);

    useImperativeHandle(ref, () => ({
      animateButton() {
        showProgress();
      },
    }));

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <StyledSubmitButton ref={btnRef} onClick={showProgress} {...props}>
        {!props.styleGuide && <ProgressBar active={active} />}
        <SubmitLabel>{children}</SubmitLabel>
      </StyledSubmitButton>
    );
  },
);
export const Subtitle = styled.h2`
  font-weight: normal;
  font-size: 21px;
  line-height: 21px;
  margin-top: 0;
  margin-bottom: 35px;
  text-decoration: none;

  ${({ noMargin }) => noMargin && `
    margin-bottom: 0;
  `}
`;
export const CardTitle = styled(Subtitle)`
  margin-bottom: 30px;
  text-align: center;
  @media ${device.tablet} {
    font-size: 20px;
    margin-bottom: 30px;
  }
`;
export const ModalTitle = styled(Subtitle)`
  font-size: 23px;
  line-height: 23px;
`;
export const ModalText = styled.p`
  font-size: 18px;
`;

export const IframeCard = styled(Card)`
  min-width: 400px;
  max-width: 725px;
  width: 100%;
`;

export const Modal = styled(Card)`
  max-width: 780px;
`;

export const Title = styled.div`
  margin-top: 35px;
  font-size: 40px;
  line-height: 52px;
  word-wrap: break-word;
  @media ${device.tablet} {
    font-size: 27px;
  }
`;
export const Intro = styled.p`
  font-size: 18px;
  line-height: 32px;
  margin-top: 35px;
  word-wrap: break-word;
  text-align: left;
`;

export const SimpleLink = styled.a`
  color: #006edb;
  text-decoration: underline;
  cursor: pointer;
`;
export const MessageText = styled.p`
  margin: 0 0 10px;
  font-size: 16px;
  line-height: 1.5;
`;

SubmitButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  styleGuide: PropTypes.bool,
};
