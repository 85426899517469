/* eslint-disable prefer-promise-reject-errors */
const origin = process.env.NODE_ENV === 'development'
  ? 'http://localhost:8080'
  : '';

export default ({
  method, path, headers, body,
}) => new Promise((resolve, reject) => {
  const xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  xhr.open(method || 'GET', origin + path);
  if (headers) {
    Object.keys(headers).forEach((key) => {
      xhr.setRequestHeader(key, headers[key]);
    });
  } else {
    xhr.setRequestHeader('Content-type', 'application/json');
    xhr.setRequestHeader('Accept', 'application/json');
  }
  xhr.onload = () => {
    if (xhr.status >= 200 && xhr.status < 300) {
      resolve({ status: xhr.status, response: xhr.response });
    } else {
      reject({
        status: xhr.status,
        response: xhr.response,
        statusText: xhr.statusText,
      });
    }
  };
  xhr.onerror = () => reject({
    status: xhr.status,
  });
  xhr.send(body);
});
