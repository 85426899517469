import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Button, SubmitButton } from './StyledComponentsLegacy';
import { device } from './StyledComponentsCommon';

export const MainTitle = styled.h1`
  font-family: var(--zephr-typography-title-font);
  color: var(--zephr-color-text-main);
  font-weight: bold;
  font-size: 24px;
  margin: 0 0 30px;
  @media ${device.tablet} {
    font-size: 20px;
    margin-bottom: 30px;
  }

  ${({ isCanceled }) => isCanceled && `
    text-decoration: line-through;
  `};
`;
export const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  line-height: 30px;
  font-size: 14px;
  font-weight: bold;
  color: var(--zephr-color-text-main);
  
  ${({ withMargin }) => withMargin && `
    margin-bottom: 20px;
  `}

  ${({ noMargin }) => noMargin && `
    margin-bottom: 0;
  `}
`;
export const Divider = styled.hr`
  border: solid var(--zephr-color-background-tinted);
  border-width: 1px 0 0;
  margin: ${({ margin }) => margin ?? '30px 0'};
`;
export const Information = styled.p`
  font-family: var(--zephr-typography-body-font), var(--zephr-typography-body-fallbackFont);
  font-size: 14px;
  margin: 0;
  color: var(--zephr-color-text-main);
`;

export const Title = styled.h3`
  color: var(--zephr-color-text-main);
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 10px;
`;
export const TitleDescription = styled(Information)`
  color: var(--zephr-color-text-tinted);
`;
export const SimpleLink = styled.a`
  color: var(--zephr-color-action-main);
  font-size: 14px;
  cursor: pointer;

  ${({ disabled }) => disabled && `
    color: var(--zephr-color-background-tinted);
  `}
`;
export const SimpleLinkMediumSize = styled(SimpleLink)`
  font-size: 17px;
`;
export const ButtonStyleGuide = styled(Button)`
  color: var(--zephr-color-background-main);
  font-family: var(--zephr-typography-body-font), var(--zephr-typography-body-fallbackFont);
  background-color: var(--zephr-color-action-main);
  font-size: calc(var(--zephr-button-fontSize) * 1px);
  font-weight: var(--zephr-button-fontWeight);
  border-radius: calc(var(--zephr-button-borderRadius) * 1px);
  height: calc(var(--zephr-button-height) * 1px);
  border: calc(var(--zephr-button-borderWidth) * 1px) solid var(--zephr-color-action-tinted);
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus,
  &:hover {
    background-color: var(--zephr-color-action-tinted);
  }
`;
export const PrimaryButton = styled(Button)`
  font-family: var(--zephr-typography-body-font), var(--zephr-typography-body-fallbackFont);
  background-color: var(--zephr-color-action-main);
  font-size: calc(var(--zephr-button-fontSize) * 1px);
  font-weight: var(--zephr-button-fontWeight);
  color: var(--zephr-color-background-main);
  border-radius: calc(var(--zephr-button-borderRadius) * 1px);
  height: calc(var(--zephr-button-height) * 1px);
  border: calc(var(--zephr-button-borderWidth) * 1px) solid var(--zephr-color-action-tinted);
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus,
  &:hover {
    background-color: var(--zephr-color-action-tinted);
  }

  ${({ disabled }) => disabled && `
    border-width: 0px;
    background-color: var(--zephr-color-background-tinted);
    color: var(--zephr-color-background-main);
    &:hover {
      background-color: var(--zephr-color-background-tinted);
    }
  `}
`;

export const SubmitButtonStyleGuide = styled(SubmitButton)`
  font-family: var(--zephr-typography-body-font), var(--zephr-typography-body-fallbackFont);
  font-size: calc(var(--zephr-button-fontSize) * 1px);
  font-weight: var(--zephr-button-fontWeight);
  color: var(--zephr-color-background-main);
  background-color: var(--zephr-color-action-main);
  border-radius: calc(var(--zephr-button-borderRadius) * 1px);
  height: calc(var(--zephr-button-height) * 1px);
  border: calc(var(--zephr-button-borderWidth) * 1px) solid var(--zephr-color-action-tinted);
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus,
  &:hover {
    background-color: var(--zephr-color-action-tinted);
  }
  ${({ disabled }) => disabled && `
    border-width: 0px;
    background-color: var(--zephr-color-background-tinted);
    color: var(--zephr-color-background-main);
    &:hover {
      background-color: var(--zephr-color-background-tinted);
    }
  `}
`;

export const Badge = styled.span`
  font-family: var(--zephr-typography-body-font), var(--zephr-typography-body-fallbackFont);
  display: inline-block;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #ababab;
  border-radius: 7px;
  margin: 0 10px;
  padding: 0 10px;
  text-decoration: none;

  ${({ noMargin }) => noMargin && `
    margin: 0;
  `}
`;

export const Table = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns};
`;

export const TableHead = styled.div`
  display: contents;
  & > div {
    font-size: 13px;
    padding: 13px 10px 10px;
    border-bottom: 1px solid var(--zephr-color-background-tinted);
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`;

export const TableRow = styled.div`
  display: contents;
  & > div {
    font-size: 14px;
    height: 60px;
    padding: 0 10px;
    border-bottom: 1px solid var(--zephr-color-background-tinted);
    display: flex;
    align-items: center;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Caret = styled(FontAwesomeIcon).attrs({
  icon: faChevronRight,
})`
  color: var(--zephr-color-text-tinted);
  margin-left: auto;
  font-size: 20px; 
`;

export const LeftContainerTitle = styled.div`
  font-family: var(--zephr-typography-title-font);
  color: var(--zephr-color-text-main);
  margin-top: 35px;
  font-size: 40px;
  line-height: 52px;
  word-wrap: break-word;
  @media ${device.tablet} {
    font-size: 27px;
  }
  ${({ center }) => center && `
    margin-top: 20px;
    @media ${device.tablet || device.mobile || device.mobileSm} {
      font-size: 26px;
      text-align: center;
    }
  `}
`;

export const Intro = styled.p`
  font-family: var(--zephr-typography-body-font);
  color: var(--zephr-color-text-main);
  font-size: 18px;
  line-height: 32px;
  margin-top: 35px;
  word-wrap: break-word;
  text-align: left;
  ${({ center }) => center && `
    margin-top: 9px;
    max-width: 440px;
    line-height: 20px;
    text-align: center;
    @media ${device.tablet || device.mobile || device.mobileSm} {
      font-size: 14px;
      text-align: center;
      max-width: 300px;
    }
  `}
`;

export const Subtitle = styled.h2`
  font-weight: normal;
  font-size: 21px;
  line-height: 21px;
  margin-top: 0;
  margin-bottom: 35px;
  text-decoration: none;

  ${({ noMargin }) => noMargin && `
    margin-bottom: 0;
  `}
`;

export const CardTitle = styled(Subtitle)`
  font-family: var(--zephr-typography-title-font);
  color: var(--zephr-color-text-main);
  margin-bottom: 30px;
  text-align: center;
  @media ${device.tablet} {
    font-size: 20px;
    margin-bottom: 30px;
  }
`;

export const PageWrapper = styled.section`
  display: flex;
  justify-content: center;
  background-color: ${({ background }) => background ?? 'transparent'};
  ${({ center }) => center && `
    flex-direction: column;
  `}
  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

export const MessageText = styled.p`
  font-family: var(--zephr-typography-body-font);
  color: var(--zephr-color-text-main);
  margin: 0 0 10px;
  font-size: 16px;
  line-height: 1.5;
`;

export const CenteredText = styled.p`
  font-family: var(--zephr-typography-body-font);
  color: var(--zephr-color-text-main);
  margin: 20px 0;
  font-size: 17px;
  text-align: center;
`;

export const SocialButton = styled(Button)`
  font-family: var(--zephr-typography-body-font), var(--zephr-typography-body-fallbackFont);
  font-size: calc(var(--zephr-button-fontSize) * 1px);
  font-weight: var(--zephr-button-fontWeight);
  border-radius: calc(var(--zephr-button-borderRadius) * 1px);
  height: calc(var(--zephr-button-height) * 1px);
  color: #000;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #fafafa;
  }
`;

export const UnorderedList = styled.ul`
  padding: 15px;
  margin: 0;
`;
export const ListItem = styled.li`
  font-family: var(--zephr-typography-body-font), var(--zephr-typography-body-fallbackFont);
  color: var(--zephr-color-text-main);
  height: 70px;
  display: flex;
  align-items: center; 
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
  span {
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
