import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactModal, { BaseModalBackground } from 'styled-react-modal';
import styled from 'styled-components';
import { Button, PrimaryButton } from '../StyledComponentsLegacy';
import { device } from '../StyledComponentsCommon';
import { PrimaryButton as PrimaryButtonStyleGuide } from '../StyledComponents';
import { ErrorAlert } from './alerts';

export const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);

  const toggleModal = () => {
    setIsShowing(!isShowing);
  };

  return {
    isShowing,
    toggleModal,
  };
};

export const ModalTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalCloseBtn = styled.div`
  background-color: #006edb;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: #005ebd;
  }
  &:before, &:after {
    content: '';
    position: absolute;
    border-left: 2px solid #fff;
    height: 16px;
    top: 50%;
    left: 50%;
  }
  &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

export const ModalCloseBtnStyleGuide = styled(ModalCloseBtn)`
  background-color: var(--zephr-color-action-main);
  &:hover {
    background-color: var(--zephr-color-action-tinted);
  }
`;

export const ModalBackground = styled(BaseModalBackground)`
  display: flex;
  position: fixed;
  align-items: start;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  opacity: ${(props) => props.opacity};
  background-color: rgba(250,250,250,0.9);
`;

const StyledReactModal = ReactModal.styled`
  width: 95vw;
  height: auto;
  max-width: ${(props) => (props.isWide ? '1130px' : '800px')};
  max-height: 85vh;
  margin-top: 10vh;
  margin-bottom: 5vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: "Source Sans Pro", sans-serif;
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: 9px;
  box-shadow: 0px 0px 8px rgba(0,0,0,0.1);
  color: #000;
  transition: opacity ease .25s;
  z-index: 100;

  & > form {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  ${({ styleGuide }) => styleGuide && `
    font-family: var(--zephr-typography-body-font);
    background-color: var(--zephr-color-background-main);
    color: var(--zephr-color-text-main);
  `}
`;

export const Modal = ({ children, ...props }) => {
  const [shouldCloseModal, setShouldCloseModal] = useState(true);

  const handleModalMouseDown = () => {
    setShouldCloseModal(false);
  };

  const handleModalMouseUp = () => {
    setShouldCloseModal(true);
  };

  const handleBackgroundClick = () => {
    if (shouldCloseModal && props.onBackgroundClick) {
      props.onBackgroundClick();
    }
    setShouldCloseModal(true);
  };

  return (
    <StyledReactModal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onBackgroundClick={handleBackgroundClick}
      onMouseDown={handleModalMouseDown}
      onMouseUp={handleModalMouseUp}
    >
      {children}
    </StyledReactModal>
  );
};

export const ModalBody = styled.div.attrs(() => ({
  className: 'modal-body',
}))`
  font-size: 18px;
  flex: 1;
  padding-top: 60px;
  padding-bottom: 20px;
  padding-left: 60px;
  padding-right: 60px;
  overflow: auto;
  position: relative;
  
  @media ${device.mobile} {
    padding-top: 50px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const ModalFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 20px 60px 50px;
  font-size: 18px;

  @media ${device.mobile} {
    padding: 20px 30px 40px;
  }
`;

export const CancelModalFooter = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  padding: 20px 60px 50px;
  font-size: 18px;

  @media ${device.mobile} {
    padding: 20px 30px 40px;
  }
`;

export const ModalTitle = styled.h2`
  font-weight: normal;
  font-size: 22px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 35px;
  text-decoration: none;

  ${(props) => props.noMargin && `
    margin-bottom: 0px;
  `}

  .svg-inline--fa {
    width: 40px;
    height: 40px;
    vertical-align: -12px;
    margin-right: 10px;
    color: #de0600;
  }
`;

export const FooterSpacer = styled.div`
  margin-left: auto;
`;

const StyledPrimaryButtonStyleGuide = styled(PrimaryButtonStyleGuide)`
    background-color: var(--zephr-color-background-main);
    color: var(--zephr-color-action-main);
    &:focus,
    &:hover {
        color: var(--zephr-color-background-main);
    }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    background-color: var(--zephr-color-background-main);
    color: var(--zephr-color-action-main);
`;

export const ButtonSpacer = styled.div`
  width: 50px;
`;

export const ConfirmationModal = ({
  isOpen,
  onCancel,
  onConfirm,
  onAsync = false,
  reverseButtons = false,
  modalTitle,
  modalBody,
  showCancel = false,
  cancelText = 'Confirm',
  confirmText = 'Confirm',
  loading = false,
  hasError = false,
  noButtons = false,
  styleGuide,
}) => {
  const PrimaryButtonTag = styleGuide ? PrimaryButtonStyleGuide : PrimaryButton;
  const CancelButtonTage = styleGuide ? StyledPrimaryButtonStyleGuide : StyledPrimaryButton;
  return (
    <Modal
      isOpen={isOpen}
      onBackgroundClick={onCancel}
      onEscapeKeydown={onCancel}
      styleGuide={styleGuide}
    >
      <ModalBody>
        {!showCancel && (
        <ModalTop>
          <ModalTitle noMargin={!modalBody}>
            {modalTitle}
          </ModalTitle>
          {styleGuide && <ModalCloseBtnStyleGuide onClick={onCancel} />}
        </ModalTop>
        )}
        {modalBody}
        {hasError && (
          <ErrorAlert
            isShowingAlert
            style={{ marginTop: 20, marginBottom: 0 }}
            primaryMessage="Something went wrong!"
            secondaryMessage="Try again later."
          />
        )}
      </ModalBody>
      {!noButtons && showCancel
        && (
          <CancelModalFooter>
            <CancelButtonTage
              onClick={onCancel}
              disabled={loading || onAsync || hasError}
            >
              {cancelText}
            </CancelButtonTage>
            <ButtonSpacer />
            <PrimaryButtonTag
              onClick={onConfirm}
              disabled={loading || onAsync || hasError}
            >
              {confirmText}
            </PrimaryButtonTag>
          </CancelModalFooter>
        )}
      {!noButtons && !showCancel
        && (
          <ModalFooter>
            {reverseButtons && (
            <PrimaryButtonTag
              onClick={onConfirm}
              disabled={onAsync || hasError}
            >
              {confirmText}
            </PrimaryButtonTag>
            )}
            {!styleGuide && onCancel && <Button onClick={onCancel} disabled={loading}>Cancel</Button>}
            {!reverseButtons && (
            <PrimaryButtonTag
              onClick={onConfirm}
              disabled={onAsync || hasError}
            >
              {confirmText}
            </PrimaryButtonTag>
            )}
          </ModalFooter>
        )}
    </Modal>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  onBackgroundClick: PropTypes.func,
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onAsync: PropTypes.bool,
  reverseButtons: PropTypes.bool,
  modalTitle: PropTypes.string,
  modalBody: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  showCancel: PropTypes.bool,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  noButtons: PropTypes.bool,
  loading: PropTypes.bool,
  hasError: PropTypes.bool,
  styleGuide: PropTypes.bool,
};
