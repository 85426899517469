import styled, { css, keyframes } from 'styled-components';

const size = {
  desktop: 1280,
  desktopSm: 1000,
  convertTableBreakpoint: 1150,
  tablet: 768,
  mobile: 375,
};
export const device = {
  desktopSm: `(max-width: ${size.desktop - 1}px)`,
  tablet: `(max-width: ${size.desktopSm - 1}px)`,
  mobile: `(max-width: ${size.tablet - 1}px)`,
  mobileSm: `(max-width: ${size.mobile - 1}px)`,
  mobileTable: `(max-width: ${size.convertTableBreakpoint}px)`,
};

export const PageWrapper = styled.section`
  display: flex;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Microsoft Sans Serif', 'Segoe UI', 'Roboto',
   'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: ${({ background }) => background ?? 'transparent'};
  
  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

export const LeftContainer = styled.div`
  padding: 15vh 50px 50px 50px;
  display: flex;
  flex-basis: 50%;
  justify-content: center;
  background-color: ${({ background }) => background ?? 'transparent'};
  @media ${device.desktopSm} {
    flex-basis: 50%;
    padding: 10vh 50px 50px 50px;
  }
  @media ${device.tablet} {
    padding: 0;
    min-height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  ${({ center }) => center && `
    padding: 20px 50px 20px;
    @media ${device.desktopSm || device.mobile || device.mobileSm} {
      padding: 20px 50px 20px;
    }
  `}
`;

export const RightContainer = styled.div`
  padding: 15vh 50px 50px 50px;
  display: flex;
  justify-content: flex-start;
  flex-basis: 50%;
  max-width: 725px;

  @media ${device.desktopSm} {
    flex-basis: 50%;
    padding: 10vh 50px 50px 50px;
  }
  @media ${device.tablet} {
    padding: 0;
    justify-content: center;
    width: 100%;
  }
  ${({ center }) => center && `
    justify-content: center;
    max-width: unset;
    padding: 20px 50px 50px;
    @media ${device.desktopSm || device.mobile || device.mobileSm || device.tablet} {
      padding-top: 20px;
    }
  `}
`;

export const LogoContainer = styled.div`
  width: auto;
  position: relative;
  max-width: 505px;
  @media ${device.tablet} {
    text-align: center;
    padding: ${({ noPadding }) => (noPadding ? 0 : '20px 20px 40px')};
  }
  ${({ center }) => center && `
    text-align: center;
    max-width: initial;
    @media ${device.tablet || device.mobile || device.mobileSm || device.desktopSm} {
      padding: 0px 20px 0px;
    }
  `}
`;

export const Brand = styled.img.attrs(({ logoSrc }) => ({ src: logoSrc }))`
  width: 300px;
  max-height: 300px;
  object-fit: contain;
  @media ${device.tablet} {
    max-height: 125px;
  }
  ${({ center }) => center && `
    max-height: 175px;
    @media ${device.tablet} {
      max-height: 125px;
    }
    @media ${device.mobile || device.mobileSm} {
      max-height: 100px;
    }
  `}
`;

const fadeOutIn = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

export const cardTransition = (duration) => css`
  animation: ${fadeOutIn} ${duration};
`;

export const Card = styled.div`
  height: min-content;
  background-color: #fff;
  width: 100%;
  max-width: 440px;
  padding: 40px;
  border-radius: 9px;
  box-shadow: 0px 0px 11px rgba(0,0,0,0.16);
  font-size: 17px;
  input:-webkit-autofill {
    font-size: 19px;
  }
  margin-left: auto;
  margin-right: 0;
  ${({ center }) => center && `
    margin-left: 0;
  `}

  @media ${device.tablet} {
    margin-left: 0;
  }
  @media ${device.mobile} {
    box-shadow: none;
    background-color: transparent;
    padding: 0 20px 40px;
  }
  ${({ transition, duration }) => transition && cardTransition(duration)}
`;

export const Spacer = styled.div`
  height: 30px;

  ${({ xs }) => xs && `
    height : 15px
  `}
  ${({ sm }) => sm && `
    height : 20px
  `}
  ${({ xl }) => xl && `
    height : 50px
  `}
`;

export const HorizontalLine = styled.hr`
  border: 0;
  border-top: 1px solid #ddd;
  margin: 40px 0 30px;
`;

export const HorizontalLineWithText = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-basis: 100%;
  align-items: center;
  color: #ddd;
  &:before, &:after {
    content: "";
    flex-grow: 1;
    background: #ddd;
    height: 1px;
    font-size: 0;
    line-height: 0;
    margin: 0 8px;
  }
`;
