import React, { useCallback, useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import request from './utils/request';
import {
  Title, CardTitle, Label, Input, Button, SubmitButton,
} from './StyledComponentsLegacy';
import {
  PageWrapper, LeftContainer, RightContainer, LogoContainer, Brand, Card, Spacer,
} from './StyledComponentsCommon';

const ConsentList = styled.ul`
  padding-inline-start: 20px;
`;
const ListItem = styled.li``;

const logoUrl = process.env.REACT_APP_OAUTH_LOGO_URL
    || 'https://zephr-which-logos-auth2.s3.eu-west-2.amazonaws.com';

const OAuthLogin = () => {
  const [consentText, setConsentText] = useState('');
  const [background, setBackground] = useState('');
  const [title, setTitle] = useState('');
  const [identifierPlaceholder, setIdentifierPlaceholder] = useState('');
  const [logoSrc, setLogoSrc] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [consentItems, setConsentItems] = useState([]);
  const location = useLocation();
  const [mode, setMode] = useState();
  const [loginStep, setLoginStep] = useState(1);
  const [fetchComplete, setFetchComplete] = useState(false);
  const [error, setError] = useState({ show: false, msg: '' });
  const [params, setParams] = useState();
  const [submitting, setSubmitting] = useState(false);

  const redirectResponse = (response) => {
    const redirectUrl = new URL(response.redirect_uri);
    redirectUrl.searchParams.append('state', response.state);

    if ('code' in response) {
      redirectUrl.searchParams.append('code', response.code);
    }
    if ('error' in response) {
      redirectUrl.searchParams.append('error', response.code);
    }

    window.location.replace(redirectUrl.href);
  };

  const userConsent = useCallback((allow) => {
    setSubmitting(true);
    request({
      method: 'POST',
      path: '/zephr/oauth2/grant',
      body: JSON.stringify({ ...params, allow }),
    }).then(({ status, response }) => {
      console.log({ status, response });
      redirectResponse(JSON.parse(response));
    }).catch(({ status, response }) => {
      console.error(status, response);
      redirectResponse(JSON.parse(response));
    });
  }, [params]);

  const checkExistingConsent = useCallback(async () => {
    try {
      const { response } = await request({ path: `/zephr/oauth2/consent/${params.client_id}` });
      const r = JSON.parse(response);
      const scopeList = params.scope.split(/[ ,]+/).filter(Boolean);
      const userConsented = scopeList.every((consentScope) => (
        (r.consent || []).includes(consentScope)
      ));

      if (!userConsented) {
        return { type: 'REQUIRES_CONSENT' };
      }
      userConsent(true);
      return { type: 'CONSENTED' };
    } catch {
      return { type: 'UNAUTHENTICATED' };
    }
  }, [params, userConsent]);

  const getData = useCallback(async () => {
    try {
      const { response } = await request({ path: '/zephr/oauth2/config' });
      const r = JSON.parse(response);
      setConsentText(r.consent_text ?? '');
      setBackground(r.background ?? '#fff');
      setTitle(r.login_text ?? '');
      setIdentifierPlaceholder(r.identifier_placeholder ?? '');
      setLogoSrc(`${logoUrl}/${r.logo_id}` ?? '');
    } catch {
      console.log('no config');
    }

    const result = await checkExistingConsent();
    console.log(result);
    if (result.type === 'REQUIRES_CONSENT') {
      setMode('CONSENT');
    } else if (result.type === 'UNAUTHENTICATED') {
      setMode('LOGIN');
    }
    setFetchComplete(true);
  }, [checkExistingConsent]);

  useEffect(() => {
    if (params) {
      getData();
    }
  }, [params, getData]);

  useEffect(() => {
    const {
      // eslint-disable-next-line camelcase
      scope, state, client_id, response_type, redirect_uri,
    } = queryString.parse(location.search);
    setParams({
      state, client_id, scope, response_type, redirect_uri,
    });
    if (!scope) return;
    const contains = (string) => scope.indexOf(string) > -1;
    setConsentItems([
      ...(contains('account:read') ? ['Access account information'] : []),
      ...(contains('profile:read') ? ['Access profile'] : []),
      ...(contains('profile:update') ? ['Update profile'] : []),
    ]);
  }, [location]);

  const onLoginSubmit = async (e) => {
    e.preventDefault();
    if (loginStep === 1) setLoginStep(2);
    if (loginStep === 2) {
      try {
        await request({
          method: 'POST',
          path: '/zephr/oauth2',
          body: JSON.stringify({ identifier, password }),
        });
      } catch ({ status }) {
        if (status === 401) setError({ show: true, msg: 'Wrong password' });
        else if (status === 404) {
          setLoginStep(1);
          setError({ show: true, msg: 'User not found' });
        } else {
          setLoginStep(1);
          setError({ show: true, msg: 'Something went wrong!' });
        }
        return;
      }

      const result = await checkExistingConsent();
      if (result.type === 'REQUIRES_CONSENT') {
        setMode('CONSENT');
      } else if (result.type === 'UNAUTHENTICATED') {
        setLoginStep(1);
        setError({ show: true, msg: 'Something went wrong!' });
      }
    }
  };

  return fetchComplete ? (
    <PageWrapper>
      <LeftContainer background={background}>
        <LogoContainer>
          <Brand logoSrc={logoSrc} />
          <Title>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: mode === 'LOGIN' ? title : consentText }} />
          </Title>
        </LogoContainer>
      </LeftContainer>
      <RightContainer>
        <Card>
          <form onSubmit={onLoginSubmit}>
            {mode === 'LOGIN' && (
              <>
                <CardTitle>
                  Login to your account
                </CardTitle>
                {loginStep === 1 && (
                  <>
                    <Label htmlFor="identifier">
                      <Input
                        type="text"
                        id="identifier"
                        name="identifier"
                        required
                        value={identifier}
                        placeholder={identifierPlaceholder}
                        onChange={(e) => {
                          setIdentifier(e.target.value);
                          setError({ ...error, show: false });
                        }}
                        error={error}
                      />
                    </Label>
                    <Spacer sm />
                    <SubmitButton>Continue</SubmitButton>
                  </>
                )}
                {loginStep === 2 && (
                  <>
                    <Label htmlFor="password">
                      Password
                      <Input
                        type="password"
                        id="password"
                        name="password"
                        required
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setError({ ...error, show: false });
                        }}
                        error={error}
                      />
                    </Label>
                    <Spacer sm />
                    <SubmitButton>Login</SubmitButton>
                  </>
                )}
              </>
            )}
            {mode === 'CONSENT' && (
              <>
                <p>This app would like to:</p>
                <ConsentList>
                  {consentItems.map((item) => (
                    <ListItem key={item}>{item}</ListItem>
                  ))}
                </ConsentList>
                <Spacer sm />
                <Button
                  onClick={() => userConsent(false)}
                  disabled={submitting}
                >
                  Deny
                </Button>
                <SubmitButton
                  noMargin
                  onClick={() => userConsent(true)}
                  disabled={submitting}
                >
                  Allow
                </SubmitButton>
              </>

            )}
          </form>
        </Card>
      </RightContainer>
    </PageWrapper>
  ) : null;
};

export default OAuthLogin;
